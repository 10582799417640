<template>
  <!--  <div class="mb-3 card">
      <div>
        <v-container fluid>
          <v-layout>
            <v-flex md5 xs12>
            </v-flex>
            <v-flex md5 xs12>
              <v-alert type="info" style="position:absolute; right:25px;">
                <h5>Deine 3POS ID lautet: {{ api.auth.posID }}</h5>
              </v-alert>
              <br style="clear: both">
            </v-flex>
          </v-layout>


          <v-layout>
            <v-flex md12 xs12>
              <h3>Fernwartung:</h3>
              <h6>{{ $t('generic.lang_helpBody1') }}<br>
                {{ $t('generic.lang_helpBody2') }}</h6>
            </v-flex>

          </v-layout>

          <v-layout mt-8>
            <v-flex md6 xs12>
              <a href="https://bs1.billsys.de/fernwartung/AnyDesk.exe" target="_blank" class="btn btn-info"
                 style="color:#ffffff;">{{ $t('generic.lang_helpBody3') }}</a>
            </v-flex>
            <v-flex md6 xs12>
              <a href="https://bs1.billsys.de/fernwartung/anydesk.dmg" target="_blank" class="btn btn-info"
                 style="color:#ffffff;">{{ $t('generic.lang_helpBody4') }}</a><br><br>
            </v-flex>
          </v-layout>

          <v-layout mt-10>or copy from en
            <v-flex md6 xs12>
              {{ $t('generic.lang_helpBody6') }}
            </v-flex>
            <v-flex md6 xs12>
              <b>24/7 eMail-Support:</b> <a href="mailto:support@billsys.de">support@billsys.de</a><br><br>
            </v-flex>
          </v-layout>
          <br>
          <h4>Support Zugang:</h4>
          <p>Mit dem Supportzugang erlaubst Du unserem Support auf dein Kassensystem zuzugreifen um dir zu helfen.
            Der Zugang erlischt automatisch anch 48 Stunden oder wenn du diesen hier wieder deaktivierst.</p>
          <v-btn @click="supportAccess" color="warning">Support-Zugang aktivieren</v-btn>
        </v-container>
      </div>
    </div> -->


  <v-container class="ma-0 pa-0" fluid>
    <v-card outlined>
      <v-card-title class="pa-0">
        <v-row align="center" class="ma-0 help-header pt-5 pb-5" justify="center">
          <v-col class="text-center pb-1" cols="12">
            <h4 class="mx-auto text-light">{{this.$t('help.lang_helpHeader')}}</h4>
          </v-col>

          <!-- here we will show list of our support employees -->
          <v-col class="text-center pt-1" cols="12">
            <v-avatar
                class="elevation-0 support-avatar ma-1"
                color="orange"
                size="40"

            >
              <strong class="text-light">3</strong>
            </v-avatar>

            <v-avatar
                class="elevation-0 support-avatar ma-1"
                color="blue"
                size="40"

            >
              <strong class="text-light">P</strong>
            </v-avatar>

            <v-avatar
                class="elevation-0 support-avatar ma-1"
                color="success"
                size="40"

            >
              <strong class="text-light">O</strong>
            </v-avatar>

            <v-avatar
                class="elevation-0 support-avatar ma-1"
                color="error"
                size="40"

            >
              <strong class="text-light">S</strong>
            </v-avatar>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text class="pa-3 pt-6">
        <v-row class="pa-2">

          <v-col class="pt-0" xs="12" md="3">
            <div class="elevation-0 ma-0">
              <h6 class="text-muted">{{this.$t('help.help_topics')}}</h6>


              <div class="">
                <a :href="false" class="url" @click="tabs = 0">{{ $t('generic.lang_remoteMaintenance') }}</a>
              </div>
              <div class="">
                <a :href="false" class="url" @click="tabs = 1">{{ $t('generic.lang_supportAccess') }}</a>
              </div>

              <div class="">
                <a :href="false" class="url" @click="tabs = 2">{{this.$t('support.lang_contactSupport')}}</a>
              </div>
              <div class="">
                <a :href="false" class="url" @click="tabs = 3">{{ $t('generic.lang_legal') }}</a>
              </div>
              <div class="">
                <a :href="false" class="url" @click="tabs = 4">{{ $t('generic.lang_instructionsOrDocuments') }}</a>
              </div>

              <div class="">
                <a :href="false" class="url" @click="tabs = 5">{{ $t('generic.lang_scaleCertification') }}</a>
              </div>

              <div v-if="$store.getters['permissions/checkPermission']('access_debug')" class="">
                <a :href="false" class="url" @click="tabs = 6">{{ $t('generic.lang_access_debugHelp') }}</a>
              </div>

              <div class="">
                <a :href="false" class="url" @click="tabs = 7">{{ $t('generic.lang_downloadSoftware') }}</a>
              </div>
            </div>

            <div class="elevation-0 mt-4">
              <h6 class="text-muted">{{ $t('help.lang_version') }}:</h6>
              <strong class="primary--text">{{ appVersion }}</strong>
            </div>

            <!--here we have to show barcode -->
            <div id="barcode" class="elevation-0 mt-4">
              <h6 class="text-muted">{{this.$t('help.lang_helpYourSystem')}}</h6>

              <strong class="primary--text">3POS ID: {{ api.auth.posID }}</strong>

              <img :src="this.barcode" width="100%"/>
            </div>


          </v-col>

          <v-col id="content" class="pt-0 mt-0" cols="12" md="9" sm="12">
            <v-tabs-items v-model="tabs" class="pt-0 mt-0">
              <v-tab-item>

                <v-layout>
                  <v-flex md12 xs12>
                    <h3 class="text-muted">{{ $t('generic.lang_helpPortal') }}</h3>
                    <h6>{{ $t('help.lang_helpPortalBody') }}<br></h6>
                    <v-btn color="warning" href="https://hilfe.3pos.de" target="_blank">{{ $t('help.lang_toHelpPortal') }}</v-btn><br><br><br>
                  </v-flex>
                </v-layout>
                  <v-layout>
                  <v-flex md12 xs12>
                    <h3 class="text-muted">{{ $t('generic.lang_remoteMaintenance') }}</h3>
                    <h6>{{ $t('generic.lang_helpBody1') }}<br>
                      {{ $t('generic.lang_helpBody2') }}</h6>
                  </v-flex>

                </v-layout>

                <v-row class="mt-8 mb-10">
                  <v-col cols="12" lg="4">
                    <a class="btn btn-info" href="https://cloud.3pos.de/f/ed84a5200258458ca6d8/?dl=1" style="color:#ffffff;"
                       target="_blank">{{ $t('generic.lang_helpBody3') }}</a>
                  </v-col>
                  <v-col cols="12" lg="4">
                    <a class="btn btn-info" href="https://cloud.3pos.de/f/072af335e2d44522811f/?dl=1" style="color:#ffffff;"
                       target="_blank">{{ $t('generic.lang_helpBody4') }}</a>
                  </v-col>
                  <v-col cols="12" lg="4">
                    <a class="btn btn-info" href="https://cloud.3pos.de/f/09af0b3d1dd4405d8643/?dl=1" style="color:#ffffff;"
                       target="_blank">{{ $t('generic.lang_remoteMaintenanceForMacArm') }}</a>
                  </v-col>
                </v-row>

                <v-layout mt-10>
                  <v-flex md6 xs12 v-html="$t('generic.lang_helpBody6')">
                  </v-flex>
                  <v-flex md6 xs12>
                    <b>{{ $t('generic.lang_emailSupport') }}</b> <a href="mailto:support@billsys.de">support@3pos.de</a><br><br>
                  </v-flex>
                </v-layout>
              </v-tab-item>

              <v-tab-item>
                <h4 class="text-muted">{{ $t('generic.lang_supportAccess') }}</h4>
                <p>{{ $t('help.lang_supportAccessBody') }}</p>
                <v-btn color="warning" @click="supportAccess">{{ $t('generic.lang_activateSupportAccess') }}</v-btn>
              </v-tab-item>

              <v-tab-item>
                <h4 class="text-muted">{{$t('support.lang_contactSupport')}}</h4>
                <p>{{$t('support.lang_contactSupportText')}}</p>

                <v-form ref="form" lazy-validation>
                  <form id="form">
                    <v-row align="center" class="mx-auto">
                      <v-col cols="12">
                        <v-text-field v-model="form.subject" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      :rules="[v => !!v]"
                                      clearable dense :label="this.$t('settings.lang_mail_subject')" outlined @focus="showTouchKeyboard"/>
                      </v-col>
                      <v-col class="pt-0 mt-0" cols="12">
                        <quill-editor v-model="form.message" :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                      :options="quillOptions" :rules="[v => !!v]"
                                      @focus="showTouchKeyboard"></quill-editor>
                      </v-col>


                      <v-col class="pt-0 mt-0" cols="12">
                        <v-file-input v-model="form.file" accept="image/*" type="file" append-icon="add_photo_alternate" dense
                                      :label="this.$t('support.lang_supportAttachScreenshot')"
                                      outlined prepend-icon=""/>
                      </v-col>


                      <v-col cols="12">
                        <v-btn :disabled="send_loading" :loading="send_loading" block class="ma-0 bg-primary text-light"
                               large
                               @click="send">
                          {{$t('support.lang_supportSend')}}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </form>
                </v-form>
              </v-tab-item>


              <v-tab-item>
                <h4 class="text-muted">{{ $t('generic.lang_legal') }}</h4>
                <p>{{ $t('help.lang_legalBody') }}</p>

                <v-btn color="warning" href="https://cloud.3pos.de/f/e08ad307b0f2470c839c/?dl=1" target="_blank">{{ $t('generic.lang_3posDataProtection') }} ({{ $t('generic.lang_stand') }} 08/21)</v-btn><br>
                <v-btn color="warning" href="https://cloud.3pos.de/f/8eac4f42e7c24bac92c7/?dl=1" target="_blank">{{ $t('generic.lang_3posTermsAndConditions') }} ({{ $t('generic.lang_stand') }} 08/21)</v-btn><br>
                <v-btn color="warning" href="https://cloud.3pos.de/f/b9b6bf4593244fa381fc/?dl=1" target="_blank">{{ $t('generic.lang_3posSoftwareLicenseTerms') }} ({{ $t('generic.lang_stand') }} 08/21)</v-btn><br>
              </v-tab-item>

              <v-tab-item>
                <h4 class="text-muted">{{ $t('generic.lang_documents') }}</h4>
                <p>{{ $t('help.lang_documentsBody') }}</p>

                <v-btn color="warning" href="https://cloud.3pos.de/f/28938a7df4514b4cb31c/?dl=1" target="_blank">{{ $t('generic.lang_3posManual') }} ({{ $t('generic.lang_stand') }} 03/22)</v-btn><br>

                <v-btn color="warning" href="https://cloud.3pos.de/f/23cc73e6159441b1b122/?dl=1" target="_blank">{{ $t('generic.lang_epsonTseComplianceNotice') }}</v-btn><br>

                <v-btn color="warning" href="https://cloud.3pos.de/f/00547602c7e2426b92f2/?dl=1" target="_blank">{{ $t('generic.lang_fiskalyTseDeclarationOfConformity') }}</v-btn><br>

                <v-btn color="warning" href="https://cloud.3pos.de/f/4eda6587ec984aa29f4c/?dl=1" target="_blank">{{ $t('generic.lang_proceduralDocumentationGoBD') }}</v-btn><br>

                <v-btn color="warning" href="https://cloud.3pos.de/f/54f9784ea7db4f64a77c/?dl=1" target="_blank">{{ $t('generic.lang_procedureDocumentationDSFinV-K') }}</v-btn><br>

              </v-tab-item>

              <v-tab-item>
                <scale-confirmity/>
              </v-tab-item>

              <v-tab-item v-if="$store.getters['permissions/checkPermission']('access_debug')">
                <h4 class="text-muted">{{ $t('generic.lang_debugTitle') }}</h4>
                <p>{{ $t('generic.lang_debugBody') }}</p>
                <v-btn color="warning" @click="resetLockedTables" :loading="loadingResetLockedTables">{{ $t('generic.lang_resetLockedTables') }}</v-btn>
              </v-tab-item>
              <v-tab-item>
                <DownloadSoftware/>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-card-text>



      <v-card-subtitle v-if="$store.getters['permissions/checkHybridState']" class="elevation-6">
        <v-row class="ma-0 pa-0">
          <v-col cols="12">
            <v-icon class="ma-2" color="primary" small>access_time</v-icon>
            <strong class="pa-1">{{ $t('generic.lang_lastContactWithHybridServer') }}:</strong>
            <span>{{ this.lastHybridContact }}</span>
          </v-col>

          <v-col cols="12"  v-if="this.lastHybridError.length > 2">
            <v-icon class="ma-2" color="primary" small>calendar_today</v-icon>
            <strong class="pa-1">{{ $t('generic.lang_lastErrorMessageHybridServer') }}:</strong>
            <span>{{this.lastHybridError}}</span>
          </v-col>
        </v-row>
      </v-card-subtitle>

    </v-card>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                          :accept="hideTouchKeyboard"
                          :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidth"/>
    </div>
  </v-container>
</template>


<script>
import {mapState} from "vuex";
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import mixin from "@/mixins/KeyboardMixIns";

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {quillEditor} from 'vue-quill-editor';

import ScaleConfirmity from "@/components/help/ScaleConfirmity.vue";
import DownloadSoftware from "@/components/help/DownloadSoftware.vue";

let self = this;
export default {
  name: "Help",
  components: {
    DownloadSoftware,
    quillEditor,
    ScaleConfirmity,
  },
  mixins: [mixin],
  data() {
    return {
      form: {
        subject: "",
        message: "",
        file: null
      },
      send_loading: false,
      loadingResetLockedTables: false,
      barcode: null,
      tabs: 0,
      lastHybridContact: "",
      lastHybridError: "",
      quillOptions: {
        placeholder: 'Deine Nachricht...',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{'header': [1, 2, 3, 4, 5, 6, false]}],
            [{'align': []}],
            [{'font': []}]
          ],
        },
      },
    }
  },
  computed: {
    ...mapState([
      'api'
    ]),
    appVersion() {
      return process.env.VUE_APP_VERSION+" ("+process.env.VUE_APP_VERSION_DATETIME+")";
    }
  },
  methods: {
    send() {
      if (!this.$refs.form.validate()) return;
      this.send_loading = true;
      let f = new FormData();

      for (let elt in this.form)
        f.append(elt , this.form[elt]);
      this.axios.post(ENDPOINTS.EMAILS.SUPPORT, f)
          .then((res) => {
            if (res.data.STATUS === "SUCCESS") {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_success'),
                color: "success"
              });
              this.$refs.form.reset();
              this.form.message = "";
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.msg,
          color: "error"
        });
      }).finally(() => {
        this.send_loading = false;
      })
    },
    getBarcode() {
      this.axios.get(ENDPOINTS.BARCODES.HELP + '?f=png&w=500&h=500&s=qr&d=' + this.api.auth.posID)
          .then((res) => {
            this.barcode = res.data;
          }).catch((err) => {
        //console.log(err);
      })
    },

    loadHybridState: function () {

      this.axios.post(ENDPOINTS.HYBRIDSTATE.INFO).then((res) => {
        if (res.data.status) {
          this.lastHybridContact = res.data.lastHybridContact;
          this.lastHybridError = res.data.lastHybridError;

          this.loading = false;
        }
      });
},

    supportAccess: function () {
      this.$swal({
        title: this.$t('generic.lang_supportAccess'),
        text: this.$t('generic.lang_supportAccessConfirmationMSG'),
        icon: "warning",
        confirmButtonText: this.$t('generic.lang_apply'),
        cancelButtonText: this.$t('generic.lang_cancel'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.axios.post(ENDPOINTS.SUPPORT.ACCESS).then((res) => {
            if (res.data.status === 'SUCCESS') {
              if (res.data.access === 'ACTIVE') {
                Events.$emit("showSnackbar", {
                  message: this.$t('generic.lang_supportAccessEnabled'),
                  color: "success"
                });
              } else {
                Events.$emit("showSnackbar", {
                  message: this.$t('generic.lang_supportAccessDisabled'),
                  color: "success"
                });
              }


            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    resetLockedTables: function () {
      this.$swal({
        title: this.$t('generic.lang_resetLockedTables'),
        text: this.$t('generic.lang_resetLockedTablesConfirmationMSG'),
        icon: "warning",
        confirmButtonText: this.$t('generic.lang_apply'),
        cancelButtonText: this.$t('generic.lang_cancel'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.$socket.emit('tableplan.resetLockedTables')
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
  },
  mounted() {
    this.getBarcode();
    /*IF HYBRID ACTIVE GET STATE DATA */
    if(this.$store.getters['permissions/checkHybridState']) {
      this.loadHybridState();
    }
  }
}
</script>

<style scoped>

.help-header {
  /* , #ff6d00a3*/
  background-image: linear-gradient(10deg, #3f6ad8, #3f6ad87d) !important;
}

.support-avatar {
  border: 1px solid white !important;
}

#content {
  border-left: 1px solid lightsteelblue;
}

a:hover {
  text-decoration: none !important;
}

.url {
  font-size: 16px;
  margin: 3px 0 3px 0 !important;
}
</style>
